import axios from '../utils/axios';

export function getAliyunSts() {
  return axios({
    url: `/aliyun/sts`,
    method: 'post',
  });
}

export function bindWord(data) {
  return axios({
    url: `/word/bind`,
    method: 'post',
    data,
  });
}

export function createImageFile(data) {
  return axios({
    url: `/image/file`,
    method: 'post',
    data,
  });
}

export function getWordMatchFile(params) {
  return axios({
    url: `/word/match/file`,
    method: 'get',
    params,
  });
}

export function getUserAvatarList() {
  return axios({
    url: `/user-avatar/list`,
    method: 'get',
  });
}

export function createAvatar({ url, type }) {
  return axios({
    url: `/user-avatar`,
    method: 'post',
    data: {
      url,
      type,
    },
  });
}

export function updateAvatarType({ id, type }) {
  return axios({
    url: `/user-avatar/${id}/type`,
    method: 'put',
    data: {
      type,
    },
  });
}

export function deleteAvatarById({ id }) {
  return axios({
    url: `/user-avatar/${id}`,
    method: 'delete',
  });
}
