/*
 * @Author: weiwenhao
 * @Date: 2022-05-24 09:48:49
 * @LastEditTime: 2022-11-17 22:46:15
 * @LastEditors: weiwenhao
 * @Description:
 * @FilePath: /aliyun-upload-modal/web/src/utils/axios.js
 *
 */
import Vue from 'vue';
import axios from 'axios';

axios.defaults.timeout = 30000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.baseURL = '/api';
axios.defaults.withCredentials = true;

//POST传参序列化
axios.interceptors.request.use(
  config => {
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//code状态码200判断
axios.interceptors.response.use(
  response => {
    const data = response.data || {};
    if (data.code !== 200) {
      Vue.prototype.$message({
        message: data.message || '网络异常！',
        type: 'error',
        duration: 3000,
      });
      return Promise.reject(data);
    }
    return data.res;
  },
  err => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误';
          break;
        case 401:
          err.message = '未授权，请登录';
          break;
        case 403:
          err.message = '拒绝访问';
          break;
        case 404:
          err.message = `请求地址出错: ${err.response.config.url}`;
          break;
        case 408:
          err.message = '请求超时';
          break;
        case 500:
          err.message = '服务器内部错误';
          break;
        case 501:
          err.message = '服务未实现';
          break;
        case 502:
          err.message = '网关错误';
          break;
        case 503:
          err.message = '服务不可用';
          break;
        case 504:
          err.message = '网关超时';
          break;
        case 505:
          err.message = 'HTTP版本不受支持';
          break;
        default:
      }
    }
    Vue.prototype.$message({
      message: err.message || '网络异常！',
      type: 'error',
      duration: 3000,
    });
    return Promise.reject(err);
  }
);

export default axios;
