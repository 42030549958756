<template>
  <div class="container">
    <div>
      <h3>头像库</h3>
      <el-upload class="upload-demo" drag multiple accept=".png,.jpg,.jpeg,.gif" :http-request="uploadToDatabase" action="" :show-file-list="false">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
    <h3>未分类（{{ otherList.length }}）</h3>
    <div class="file-list">
      <div class="file-item" v-for="v in otherList" :key="v.id" @click="handleUpdateAvatar(v)">
        <el-avatar :src="v.url" :size="66" />
        <i class="el-icon-circle-close" @click.stop="handleDeleteAvatar(v)"></i>
      </div>
    </div>
    <h3>男生头像（{{ boyList.length }}）</h3>
    <div class="file-list">
      <div class="file-item" v-for="v in boyList" :key="v.id" @click="handleUpdateAvatar(v)">
        <el-avatar :src="v.url" :size="66" />
        <i class="el-icon-circle-close" @click.stop="handleDeleteAvatar(v)"></i>
      </div>
    </div>
    <h3>女生头像（{{ girlList.length }}）</h3>
    <div class="file-list">
      <div class="file-item" v-for="v in girlList" :key="v.id" @click="handleUpdateAvatar(v)">
        <el-avatar :src="v.url" :size="66" />
        <i class="el-icon-circle-close" @click.stop="handleDeleteAvatar(v)"></i>
      </div>
    </div>

    <el-dialog custom-class="user-avatar-manage-update-dialog" title="修改头像" :visible.sync="isUpdateVisible" width="520px" :before-close="handleCloseUpdateDialog">
      <div v-if="updateData" class="update-wrap">
        <el-avatar :src="updateData.url" :size="66" />
        <div class="update-type-wrap">
          <el-radio v-model="updateData.type" label="">未分类</el-radio>
          <el-radio v-model="updateData.type" label="boy">男孩</el-radio>
          <el-radio v-model="updateData.type" label="girl">女孩</el-radio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseUpdateDialog" size="mini">取 消</el-button>
        <el-button type="primary" @click="handleConfirmUpdateDialog" size="mini">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAliyunSts, getUserAvatarList, createAvatar, updateAvatarType, deleteAvatarById } from '@/api';
import { v4 as uuidv4 } from 'uuid';

const OSS = require('ali-oss');

export default {
  data() {
    return {
      client: null,
      avatarList: [],

      isUpdateVisible: false,
      updateData: null,
    };
  },
  computed: {
    otherList() {
      return this.avatarList.filter(v => v.type === '');
    },
    boyList() {
      return this.avatarList.filter(v => v.type === 'boy');
    },
    girlList() {
      return this.avatarList.filter(v => v.type === 'girl');
    },
  },
  async created() {
    this.getAvatarList();
    this.getAliyunSts();
  },
  methods: {
    async getAliyunSts() {
      const { AccessKeyId, AccessKeySecret, SecurityToken } = await getAliyunSts();
      const config = {
        region: 'oss-cn-qingdao',
        accessKeyId: AccessKeyId,
        accessKeySecret: AccessKeySecret,
        stsToken: SecurityToken,
        bucket: 'aks-voices',
      };
      this.client = new OSS(config);
    },
    async getAvatarList() {
      const res = await getUserAvatarList();
      this.avatarList = res;
    },
    async uploadToDatabase({ file }) {
      const { res, url } = await this.client.put(`avatar/${uuidv4()}.png`, file);
      if (res.status !== 200) {
        this.$message.warning(`上传失败：${file.name}`);
        return;
      }

      const result = await createAvatar({ type: '', url });
      if (result) {
        this.getAvatarList();
      }
    },

    handleDeleteAvatar(v) {
      this.$confirm('确定删除该头像?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteAvatarById({ id: v.id });
        if (res) {
          this.$message.success('删除成功!');
          this.getAvatarList();
        }
      }).catch(() => {
      });
    },
    handleUpdateAvatar(v) {
      this.updateData = {
        ...v,
      };
      this.isUpdateVisible = true;
    },
    handleCloseUpdateDialog() {
      this.isUpdateVisible = false;
      this.updateData = null;
    },
    async handleConfirmUpdateDialog() {
      const res = await updateAvatarType({
        id: this.updateData.id,
        type: this.updateData.type,
      });
      if (res) {
        this.$message.success('修改成功!');
        this.getAvatarList();
        this.isUpdateVisible = false;
        this.updateData = null;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.upload-demo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 800px;

  /deep/ .el-upload {
    width: 100%;

    .el-upload-dragger {
      width: 100%;
      height: 320px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .el-icon-upload {
        margin-top: -10px;
      }
    }
  }

  /deep/ .el-upload-list {
    width: 100%;
  }
}

.file-list {
  display: grid;
  grid-template-columns: repeat(10, 66px);
  grid-gap: 12px;

  .file-item {
    margin-right: 10px;
    cursor: pointer;
    position: relative;

    &:hover {
      .el-icon-circle-close {
        display: block;
      }
    }

    .el-icon-circle-close {
      font-size: 20px;
      position: absolute;
      top: 0;
      right: -8px;
      color: red;
      display: none;
    }
  }
}

.update-wrap {
  .update-type-wrap {
    margin-top: 12px;
  }
}
</style>


<style lang="less">
.user-avatar-manage-update-dialog {
  .el-dialog__body {
    padding: 10px 20px;
  }
}
</style>