import VueRouter from 'vue-router';

import ImageBox from '../pages/word-image-manage.vue';
import Avatar from '../pages/user-avatar-manage.vue';

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/word-image-manage', component: ImageBox },
    { path: '/user-avatar-manage', component: Avatar },
  ],
});

export default router;
