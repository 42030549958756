<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import cookies from 'js-cookie';

export default {
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (!token && !cookies.get('token')) {
      this.$message.error('未授权登录');
      return;
    }
    if (token) {
      cookies.set('token', token);
    }
  },
};
</script>

<style lang="less">
.container {
}
</style>
