<template>
  <div class="container">
    <div>
      <h3>上传到单词库</h3>
      <el-upload class="upload-demo" drag multiple accept=".png,.jpg,.jpeg,.gif" :http-request="uploadToDatabase" action="">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
    <div class="word-match-file">
      <div class="input-wrap">
        <el-input class="input-item" v-model="wordMatchValue" />
        <el-button type="primary" :disabled="!wordMatchValue" @click="handleWordMatchFile">查询</el-button>
      </div>
      <div class="file-list">
        <div class="file-item" v-for="v in wordMatchFiles" :key="v.id">
          <div class="file-id">{{ v.id }}</div>
          <div class="file-name">{{ v.filename }}</div>
          <a :href="v.url" target="_blank" class="file-url">{{ v.url }}</a>
        </div>
        <div v-if="!wordMatchFiles.length">暂无数据</div>
      </div>
    </div>
    <el-divider></el-divider>
    <div>
      <h3>上传到阿里云自动更新单词图片</h3>
      <el-upload class="upload-demo" drag multiple accept=".png,.jpg,.jpeg,.gif" :http-request="uploadAutoMathWord" action="">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { getAliyunSts, bindWord, createImageFile, getWordMatchFile } from '@/api';
import cookies from 'js-cookie';

const OSS = require('ali-oss');

export default {
  data() {
    return {
      client: null,

      wordMatchValue: '',
      wordMatchFiles: [],
    };
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (!token && !cookies.get('token')) {
      this.$message.error('未授权登录');
      return;
    }
    if (token) {
      cookies.set('token', token);
    }
    this.getAliyunSts();
  },
  methods: {
    async getAliyunSts() {
      const { AccessKeyId, AccessKeySecret, SecurityToken } = await getAliyunSts();
      const config = {
        region: 'oss-cn-qingdao',
        accessKeyId: AccessKeyId,
        accessKeySecret: AccessKeySecret,
        stsToken: SecurityToken,
        bucket: 'aks-voices',
      };
      this.client = new OSS(config);
    },
    async upload(file) {
      const filePath = `word-image/${file.name}`;
      const { res, url } = await this.client.put(filePath, file);
      if (res.status !== 200) {
        this.$message.warning(`上传失败：${file.name}`);
        return;
      }
      const { name } = file;
      const last = name.lastIndexOf('.');
      const word = name.substring(0, last);
      return { name, word, url };
    },
    async uploadAutoMathWord({ file }) {
      const { word, url } = await this.upload(file);

      const result = await bindWord({ word, url });
      if (!result?.res) {
        console.log(`绑定单词失败：${file.name}`);
      }
    },
    async uploadToDatabase({ file }) {
      const { name, word, url } = await this.upload(file);

      const result = await createImageFile({ filename: name, filename_without_ext: word, url });
      console.log(result);
    },
    async handleWordMatchFile() {
      if (!this.wordMatchValue) {
        return;
      }

      const result = await getWordMatchFile({ word: this.wordMatchValue });
      this.wordMatchFiles = result;

      if (!result?.length) {
        this.$message.success('暂无数据');
      }
    }
  },
};
</script>

<style lang="less" scoped>
.upload-demo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 800px;

  /deep/ .el-upload {
    width: 100%;

    .el-upload-dragger {
      width: 100%;
      height: 320px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .el-icon-upload {
        margin-top: -10px;
      }
    }
  }

  /deep/ .el-upload-list {
    width: 100%;
  }
}

.word-match-file {
  margin: 30px 0;

  .input-wrap {
    display: flex;
    align-items: center;

    .input-item {
      width: 200px;
      margin-right: 30px;
    }
  }

  .file-list {
    margin-top: 20px;

    .file-item {
      display: flex;
      align-items: center;

      .file-id {
        width: 50px;
      }

      .file-name {
        margin-left: 20px;
        width: 150px;
      }

      .file-url {
        margin-left: 20px;
        flex: 1;
      }
    }

    .file-item + .file-item {
      margin-top: 12px;
    }
  }
}
</style>
